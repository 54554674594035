import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderBottom from "../components/HeaderBottom";

const ContactPageTemplate = ({ data, pageContext, location }) => {
  const page = data.markdownRemark.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const siteData = data.home.frontmatter;
  const { previous, next } = pageContext;
  console.log("Contact page data", page, location);

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "scroll-is--active",
        }}
      />
      <HeaderBottom />
      <main class="site-content contact-us-page" id="wrapper">
        <div class="site-content__inner">
          <div class="site-content__holder">
            <h1 class="page-title h3">Contact us</h1>
            <div class="page-content">
              <h4 class="text-sm">Contact info</h4>
              <p>
                If you have any questions, hubungi kami lewat <strong>Whatsapp, Tokopedia Chat</strong>, atau Email.
                Kita akan dengan senang hati menjawab semua pertanyaan anda.
              </p>
              <div class="info-box">
                <div class="info-box__label">General inquiries</div>
                <div class="info-box__content">
                  <a href="mailto:apexwarrior.id@gmail.com">
                    apexwarrior.id<span class="color-primary">@</span>
                    gmail.com
                  </a>
                </div>
              </div>
              <ul class="social-menu social-menu--links">
                <li>
                  <a href="https://wa.me/message/LGXWX5CG54JLE1" target="_blank"/>
                </li>
                <li>
                  <a href="https://instagram.com/apexwarrior.id" target="_blank"/>
                </li>
              </ul>
              <span class="spacer-lg">&nbsp;</span>
              <h4>Send us a message</h4>

              <form
                id="contact-form"
                name="inquiry"
                method="POST"
                data-netlify="true"
                className="form"
              >
                <input type="hidden" name="form-name" value="inquiry" />
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="name" name="name"
                        placeholder="Your Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="email" name="email"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea
                        name="input-message"
                        cols="30"
                        rows="5"
                        class="form-control"
                        name="message" id="message"
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" value="send" class="btn btn-secondary">Send message</button>
              </form>

            </div>

            <div
              class="gm-map gm-map--aside"
              data-map-style="necromancers"
              data-map-address="Pulogadung Trade Centre (PTC, Jl. Bekasi Timur Raya 8A No.15, RW.3, Rw. Terate, Kec. Cakung, Daerah Khusus Ibukota Jakarta 13920, Indonesia"
              data-map-icon="/assets/img/map-marker.png"
              data-map-zoom="15"
            >
              <div class="gm-map__info">
                Apex Indo Group<br/>
                <span class="color-primary">Headquarters</span>
                <address>Pulogadung Trade Centre (PTC) 8A No. 15 <br/>Jl. Bekasi Timur Raya, Cakung, Jakarta Timur, DKI Jakarta 13920</address>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* <div class="page-section section pt-100 pb-100">
        <div class="container">
          <div class="row">
            <div class="contact-info col-md-4 col-xs-12 mb-40">
              <h3>Contact Info</h3>

              <p>
                <i class="pe-7s-map-marker"></i>
                <span>{siteData.companyAddress}</span>
              </p>

              <p>
                <i class="pe-7s-call"></i>
                <span>
                  {" "}
                  <a href={`tel:${siteData.waNum}`} class="phone">
                    {siteData.waNum}
                  </a>
                </span>
              </p>

              <p>
                <i class="pe-7s-mail"></i>
                <a href={`mailto: ${siteData.email}`}>{siteData.email}</a>
              </p>

              <div class="contact-social">
                <a
                  href={`https://wa.me/${siteData.waNum.replace(/^0/g, "62")}`}
                >
                  <i class="fa fa-whatsapp"></i>
                </a>
                <a href={siteData.instaStoreUrl}>
                  <i class="fa fa-instagram"></i>
                </a>
              </div>
            </div>
            <div class="contact-form col-md-8 col-xs-12 mb-40">
              <h3>Contact Us</h3>
              <form
                id="contact-form"
                name="inquiry"
                method="POST"
                data-netlify="true"
              >
                <input type="hidden" name="form-name" value="inquiry" />
                <div class="row">
                  <div class="col-sm-4 col-xs-12 mb-20">
                    <label for="name">Your Name</label>
                    <input id="name" name="name" type="text" />
                  </div>
                  <div class="col-sm-4 col-xs-12 mb-20">
                    <label for="email">Your Email</label>
                    <input id="email" name="email" type="email" />
                  </div>
                  <div class="col-sm-4 col-xs-12 mb-20">
                    <label for="phone">Your Phone Number</label>
                    <input id="phone" name="phone" type="text" />
                  </div>
                  <div class="col-xs-12 mb-20">
                    <label for="message">Message</label>
                    <textarea name="message" id="message"></textarea>
                  </div>
                  <div class="col-xs-12">
                    <button type="submit" value="send">
                      {" "}
                      Send{" "}
                    </button>
                  </div>
                </div>
              </form>
              <p class="form-messege"></p>
            </div>
            <div className="section mt-40">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.93081919420447!2d106.85991095917818!3d-6.145057254238741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f59cb0357707%3A0xbc94149127c27ba6!2sJl.%20Agung%20Jaya%205%20No.31%2C%20RT.1%2FRW.16%2C%20Sunter%20Agung%2C%20Tj.%20Priok%2C%20Kota%20Jkt%20Utara%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2014350%2C%20Indonesia!5e0!3m2!1sen!2sus!4v1609326666429!5m2!1sen!2sus"
                width="100%"
                height="400"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
            <div class="col-xs-12 mt-40">
              <div id="contact-map"></div>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  );
};

export default ContactPageTemplate;

export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    home: markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        waNum
        companyAddress
        instaStoreUrl
        email
      }
    }
    markdownRemark(frontmatter: { id: { eq: "contact" } }) {
      frontmatter {
        id
        whatsappLink
        whatsappNumber
        zomatoLink
        phoneNumber
        address
        instagramLink
      }
    }
  }
`;
